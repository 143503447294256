<template>
  <el-dialog class="z-dialog" title="上传作品" :visible.sync="isShow" width="640px" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item prop="channel" label="分类">
        <el-select v-model="form.channel" placeholder="请选择" style="width: 315px;" @change="changeChannel">
          <el-option v-for="item in options" :key="item.channelId" :label="item.groupName + ' - ' + item.channelName"
            :value="item.channelId"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="name" label="作品名称">
        <el-input v-model="form.name" placeholder="请输入作品名称" style="width: 315px;" maxlength="100" />
        <span class="hint-tip"></span>
      </el-form-item>

      <!-- <el-form-item prop="author" label="作者名称" v-if="form.type ==1">
                <el-input v-model="form.author" placeholder="请输入作者名称" style="width: 315px;" maxlength="100"/>
            </el-form-item> -->

      <el-form-item prop="videoText" :label="fujianTitle" v-if="fujianFlag == 1">
        <el-upload class="upload-demo" ref="textUpload" action="api/uploadTextFile" :on-remove="onRemove1"
          :before-upload="beforeUpload1" :on-success="onSuccess1" :limit="1" :file-list="form.videoText"
          :on-exceed="onExceed1">
          <el-button size="small" type="primary">点击上传</el-button>
          <span class="hint-tip">支持word,excel,zip</span>
        </el-upload>
      </el-form-item>

      <el-form-item prop="mpgUrl" label="上传作品文件">
        <el-upload v-if="form.type == 1" class="upload-demo" action="api/uploadTextFile" :on-remove="onRemove2"
          :before-upload="beforeUpload2" :on-success="onSuccess2" :file-list="form.mpgUrl" multiple>
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">支持格式{{ acceptSuffix }}</div>
        </el-upload>

        <div v-else-if="form.type == 0 || form.type == 2" class="wu-example">
          <div class="btns" id="uploader">
            <div id="uploadbtn" class="el-button el-button--primary el-button--small">选择文件</div>
            <el-progress :percentage="percentage" v-if="percentage > 0"></el-progress>
          </div>
          <!--用来存放文件信息-->
          <div id="thelist" class="uploader-list" style="width:100%;"></div>
          <div slot="tip" class="el-upload__tip">支持格式{{ acceptSuffix }}</div>
        </div>
      </el-form-item>

      <el-form-item class="text-right">
        <el-button @click="isShow = false">取消</el-button>
        <el-button type="primary" @click="saveVideo" :disabled="isDisabled">确定</el-button>
      </el-form-item>

    </el-form>
  </el-dialog>
</template>

<script>
// import '../../assets/js/jquery-3.1.1.min.js'
import { hex_md5 } from '../../assets/js/md5.js'
// import '../../assets/js/webuploader.js'
import WebUploader from 'webuploader'

export default {
  data() {
    return {
      form: {
        id: '',
        activityId: '',
        name: "",
        channel: '',
        author: '',
        videoText: [],
        mpgUrl: [],
        type: 2
      },
      fujianFlag: 0,
      rules: {
        channel: {
          required: true,
          message: '请选择分类',
          trigger: 'change'
        },
        name: {
          required: true,
          message: "请输入作品名称",
          trigger: "blur"
        },
        author: {
          required: true,
          message: "请输入作者名称",
          trigger: "blur"
        },
        videoText: {
          required: true,
          message: "请选择上传文件",
          trigger: "change"
        },
        mpgUrl: {
          required: true,
          message: "请选择作品文件",
          trigger: "change"
        }
      },
      fileList: [],
      options: [],
      acceptSuffix: '',
      md5File: '',
      uploader: '',
      chunkSize: 10485760, //10M 10485760 30M  31457280 ,  50M  52428800      30*1024*1024
      percentage: 0,
      mergeNum: 0,
      isDisabled: false,
      checkFile: 'api/checkFile',
      checkChunk: 'api/checkChunk',
      merge: 'merge',
      upload: 'api/upload',
      chunksTotal: 0,
      fujianTitle: '',
    };
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    data: {}
  },

  computed: {
    isShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      }
    }
  },

  mounted() {

    this.initVideo();
  },

  methods: {
    initVideo() {

      this.form.activityId = this.data.id;
      this.form.id = this.data.videoId
      this.fujianFlag = this.data.fujianFlag;
      this.fujianTitle = this.data.fujianTitle;
      //				console.log(this.data.id,this.data.videoId);
      //获取分类列表
      this.$post("/queryChannelById", {
        activityId: this.form.activityId
      }).then(m => {
        if (m.resultCode == 0) {
          this.options = m.content.list;

          //修改作品
          if (this.data.videoId != '') {
            this.$post("/findUserVideoDetail", {
              videoId: this.form.id
            }).then(m => {
              if (m.resultCode == 0) {
                //							console.log(m.content.tVideo);

                var video = m.content.tVideo;
                this.form.id = video.id;
                this.form.activityId = video.activityId;
                this.form.channel = video.channelId;
                this.form.type = video.type;
                this.form.name = video.videoName;
                // this.form.author = video.author;

                this.changeChannel(this.form.channel);

                this.form.videoText.push({
                  name: video.videoTextName,
                  url: video.videoText
                });
                // console.log('video=',video);
                if (this.form.type == 0 || this.form.type == 2) {
                  if (video.mpgUrl != null) {
                    this.form.mpgUrl.push({
                      name: video.mpgName,
                      url: video.mpgUrl,
                      size: video.fileSize
                    });
                  }
                } else if (this.form.type == 1) {
                  var imgList = m.content.imgList;
                  for (var i = 0; i < imgList.length; i++) {
                    this.form.mpgUrl.push({
                      name: imgList[i].imgName,
                      url: imgList[i].imgUrl
                    });
                  }
                } else {
                  // console.log('this.form', this.form);
                }
              }
            })
          }
        }
      })
    },
    changeChannel(obj) {
      for (var i = 0; i < this.options.length; i++) {
        // console.log('this.options[i].channelId=', this.options[i].channelId, '---', "obj=", obj);
        if (this.options[i].channelId == obj) {
          this.form.type = this.options[i].type;
          this.acceptSuffix = this.options[i].acceptSuffix;
        }
      }
      // console.log('this.acceptSuffix=', this.acceptSuffix);

      this.form.videoText = [];
      this.form.mpgUrl = [];

      if (this.form.type == 0 || this.form.type == 2) {
        this.$nextTick(() => {
          this.changeType();
        })
      }
    },
    onExceed1() {
      this.$message({
        message: '只能上传一个文档，修改文档需删除之前的文档再上传',
        type: 'info'
      });
    },
    beforeUpload1(file) {
      //				console.log(file.type);
      let fileName = file.name;
      let pos = fileName.lastIndexOf('.');
      let lastName = fileName.substring(pos, fileName.length);
      // const isWord = (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/msword');
      const isWord = (lastName === '.doc' || lastName === '.docx' || lastName === '.zip' || lastName === '.xls' || lastName === '.xlsx');

      // const isJPG = file.type === '' ? false : file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/msword';
      const isLt20M = file.size / 1024 / 1024 <= 500;

      if (!isWord) {
        this.$message.error('只能上传word,excel,zip文件!');
        return false;
      }
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 500MB!');
        return false;
      }
      return isWord && isLt20M;
    },
    beforeUpload2(file) {
      let fileType = [];
      let geshi = this.acceptSuffix.split(',');
      for (var i = 0; i < geshi.length; i++) {
        const item = geshi[i].toLocaleLowerCase();
        fileType.push(item);
      }

      fileType = fileType.join("|");
      const reg = eval(`/${fileType}/i`);
      let flieArr = file.name.split(".");
      let fileSuffix = flieArr[flieArr.length - 1];
      const isJPG = reg.test(fileSuffix);
      // console.log("flieArr = ", flieArr, "    fileSuffix = ", fileSuffix);
      const isLt20M = file.size / 1024 / 1024 <= 20;

      if (!isJPG) {
        this.$message.error('上传图片只能是 ' + this.acceptSuffix + ' 格式!');
        return false;
      }
      if (!isLt20M) {
        this.$message.error('上传图片大小不能超过 20MB!');
        return false;
      }
      return isJPG && isLt20M;
    },
    onSuccess1(response) {
      var content = response.content;
      var filePath = content.filePath;
      var fileName = content.fileName;

      if (content.fileType == 'OK') {

        this.form.videoText.push({
          name: fileName,
          url: filePath
        });

        this.$message({
          message: '上传成功',
          type: 'success'
        });
      } else if (content.fileType == 'nottype') {
        this.delFile(content.filePath);
        this.videoText.splice(1, 1);
        this.$message({
          message: content.message,
          type: 'warning'
        });
      } else if (content.fileType == 'tobig') {
        this.delFile(content.filePath);
        this.videoText.splice(1, 1);
        this.$message({
          message: content.message,
          type: 'warning'
        });
      }

    },
    onSuccess2(response, file, fileList) {
      // var content = file.response.content;
      // var fileName = content.fileName;
      // var filePath = content.filePath;
      //				console.log(fileList,file,this.form.mpgUrl);

      this.form.mpgUrl = fileList;

      if (this._messageHide) this._messageHide.close();
      this._messageHide = this.$message({
        message: '上传成功',
        type: 'success'
      });

    },
    onRemove1(file) {
      // console.log(file);
      this.delFile(file.url);
      this.$refs['textUpload'].clearFiles();
      this.form.videoText = [];
    },
    onRemove2(file) {
      // console.log(file);
      for (var i = 0; i < this.form.mpgUrl.length; i++) {
        if (this.form.mpgUrl[i].name === file.name) {
          this.form.mpgUrl.splice(i, 1);
        }
      }
      this.delFile(file.url);
    },
    saveVideo() {
      let temp = this;
      this.isDisabled = true;
      this.$post("/checkActivityTime", {
        activityId: this.form.activityId,
        type: 'user'
      }).then(m => {
        var tActivity = m.content.tActivity;
        if (tActivity.show) {
          //				console.log(this.form.mpgUrl);
          this.form.mpgUrl.forEach(item => {
            if (!item.url) {
              item.url = item.response.content.filePath;
            }
          })
          // console.log('form.mpgUrl.length=',this.form.mpgUrl.length);

          this.$refs["form"].validate((valid) => {
            if (valid) {
              if (this.form.mpgUrl.length == 0) {
                temp.$message({
                  message: '请选择上传文件，待提示成功后在点击确定',
                  type: 'info'
                });
                this.isDisabled = false;
              }

              this.$post("/saveUserVideoMessage", {
                json: JSON.stringify(this.form)
              }).then(m => {
                if (m.resultCode == 0) {
                  temp.$message({
                    message: '保存成功',
                    type: 'success'
                  });
                  temp.isShow = false;
                  temp.$emit("init");
                } else {
                  this.isDisabled = false;
                }
              })

            } else {
              this.isDisabled = false;
              return false;
            }
          });
        } else {
          this.$message({
            message: '时间已过，不能保存作品！',
            type: 'warning'
          });
          return false;
        }
      })

    },

    initData: function () {
      const _this = this;

      //上传添加参数
      this.uploader.on('uploadBeforeSend', function (obj, data) {
        data.md5File = _this.md5File;
        if (_this.mergeNum < 1) {
          _this.percentage = 1;
          _this.mergeNum++;
        }
      });

      // 当有文件被添加进队列的时候
      this.uploader.on('fileQueued', function (file) {

        //console.log(file.ext, _this.acceptSuffix.toLowerCase(), $("#thelist div").length);

        if ($("#thelist div").length != 0) {
          _this.$message({
            message: '只能选择一个文件！',
            type: 'info'
          });

          _this.uploader.cancelFile(file);
          return;
        }

        if (_this.acceptSuffix.toLowerCase().indexOf(file.ext.toLocaleLowerCase()) != -1) {
          $("#thelist").append('<div id="' + file.id + '" class="item">' +
            '<h4 class="info" style="display: inline-block;margin-right: 15px">' + file.name + '</h4><i class="el-icon-close cursor btnRemoveFile"></i>' +
            //<p class="state"></p>
            '</div>');
        } else {
          _this.$message({
            message: '文件格式错误，请重新选择！',
            type: 'info'
          });

          _this.uploader.cancelFile(file);
        }

        $(".btnRemoveFile").bind("click", function () {
          _this.uploader.cancelFile(file);

          // console.log(file, _this.form.mpgUrl[0].url);
          if (_this.form.mpgUrl.length > 0) {
            _this.delFile(_this.form.mpgUrl[0].url);
          }
          _this.form.mpgUrl = [];
          _this.percentage = 0;

          var fileItem = $(this).parent();
          var id = $(fileItem).attr("id");
          $("#" + id).remove();
        });

      });

      // 文件上传过程中创建进度条实时显示。
      this.uploader.on('uploadProgress', function (file, percentage) {
        //避免用户看到上传完成卡顿
        if (parseInt(percentage * 100) > 99) {
          _this.percentage = 99;
        } else {
          _this.percentage = parseInt(percentage * 100);
        }
      });

      this.uploader.on('uploadSuccess', function (file) {
        _this.$post('merge', {
          "name": file.name,
          "md5File": _this.md5File,
          "chunks": _this.chunksTotal
        }).then(function (response) {
          if (response.content.status) {
            _this.form.mpgUrl = [];
            _this.form.mpgUrl.push({
              name: response.content.fileName,
              url: response.content.filePath
            });
            _this.percentage = 0;
            _this.$message({
              message: '文件上传成功！',
              type: 'success'
            });
          } else {
            _this.$message({
              message: '文件上传失败，请重新上传！',
              type: 'info'
            });
            _this.uploader.cancelFile(file);
          }
        });
        //					console.log('uploadSuccess',file);
      });

      //				this.uploader.on('uploadFinished', function() {
      //					console.log('uploadFinished');
      //				});
    },

    changeType() {

      const _this = this;

      //监听分块上传过程中的时间点
      WebUploader.Uploader.register({
        "before-send-file": "beforeSendFile", // 整个文件上传前
        "before-send": "beforeSend", // 每个分片上传前
        "after-send-file": "afterSendFile" // 分片上传完毕
      }, {
        init: function () {
          //							console.log(e);
        },
        //时间点1：所有分块进行上传之前调用此函数 ，检查文件存不存在
        beforeSendFile: function (file) {

          var deferred = WebUploader.Deferred();

          _this.md5File = hex_md5(file.name + file.size); //根据文件名称，大小确定文件唯一标记，这种方式不赞成使用

          $.ajax({
            type: "POST",
            url: _this.checkFile,
            data: {
              "md5File": _this.md5File, //文件唯一标记
            },
            dataType: "json",
            async: false,  // 同步
            success: function (response) {
              if (response.content.status) { //文件存在，跳过 ，提示文件存在
                deferred.reject(); //分片存在，跳过
              } else {
                deferred.resolve(); //文件不存在或不完整，发送该文件
              }
            }
          }, function (jqXHR, textStatus, errorThrown) { //任何形式的验证失败，都触发重新上传
            deferred.resolve();
          });

          return deferred.promise();
        },
        //时间点2：如果有分块上传，则每个分块上传之前调用此函数  ，判断分块存不存在
        beforeSend: function (block) {

          var deferred = WebUploader.Deferred();

          $.ajax({
            type: "POST",
            url: _this.checkChunk,
            data: {
              "md5File": _this.md5File, //文件唯一标记
              "chunk": block.chunk, //当前分块下标
            },
            dataType: "json",
            async: false,  // 同步
            success: function (response) {
              if (response.content.status) {
                deferred.reject(); //分片存在，跳过
              } else {
                deferred.resolve(); //分块不存在或不完整，重新发送该分块内容
              }
            }
          }, function (jqXHR, textStatus, errorThrown) { //任何形式的验证失败，都触发重新上传
            deferred.resolve();
          });

          return deferred.promise();
        },
        //时间点3：分片上传完成后，通知后台合成分片
        afterSendFile: function (file) {
          //						console.log('afterSendFile');
          var chunksTotal = Math.ceil(file.size / _this.chunkSize);
          _this.chunksTotal = chunksTotal;
          if (chunksTotal >= 1) {
            //合并请求
            //							$.ajax({
            //								type: "POST",
            //								url: _this.merge,
            //								data: {
            //									"name": file.name,
            //									"md5File": _this.md5File,
            //									"chunks": chunksTotal
            //								},
            //								cache: false,
            //								async: false,  // 同步
            //								dataType: "json",
            //								success: function(response) {
            //									console.log(_this.merge);
            //									if(response.content.status) {
            //
            //										_this.form.mpgUrl = [];
            //										_this.form.mpgUrl.push({
            //											name: response.content.fileName,
            //											url: response.content.filePath
            //										});
            //										_this.percentage = 0;
            //										_this.$message({
            //											message: '视频上传成功！',
            //											type: 'success'
            //										});
            //
            //									}
            //
            //								}
            //							})
          }
        }
      });

      //初始化选择文件的按钮及事件
      this.uploader = WebUploader.create({
        auto: true, // 选完文件后，是否自动上传。
        swf: '../../assets/img/Uploader.swf', // swf文件路径
        server: this.upload, // 文件接收服务端。
        pick: '#uploadbtn', // 选择文件的按钮。可选。
        chunked: true, //开启分片上传
        chunkSize: this.chunkSize, //分片大小
        chunkRetry: 2, //错误重试次数
        threads: 1, //上传并发数。允许同时最大上传进程数。
      });
      //				console.log('this.uploader=', this.uploader);

      //初始化操作文件的方法
      this.initData();

      //				console.log('编辑：',this.form.mpgUrl);

      //回显上传的视频文件
      if (this.form.mpgUrl.length > 0) {
        //					console.log(this.form.mpgUrl[0]);
        var video = this.form.mpgUrl[0];
        var obj = {};
        obj.name = video.name;
        obj.size = video.size;
        obj.lastModifiedDate = new Date().getTime();
        obj.id = video.size;
        obj.ext = video.name.substring(video.name.lastIndexOf(".") + 1);

        var file = new WebUploader.File(obj);

        //此处是关键，将文件状态改为'已上传完成'
        file.setStatus('complete');
        this.uploader.addFiles(file);
      }

    },

    delFile(filePath) {
      this.$post('/delFileByPath', {
        "filePath": filePath,
      }).then(function () {

      });
    }
  }
};
</script>

<style lang="scss">
/*@import url("../../assets/scss/bootstrap.min.css");*/

.upload-demo {
  width: 315px;
}

.webuploader-container {
  position: relative;
  width: 100px;
  overflow: hidden;
}

/*#uploadbtn {
    /deep/ .webuploader-element-invisible {
        opacity: 0;
    position: absolute;
    top: -15px;
    left: -15px;
    bottom: -15px;
    }
}*/

.webuploader-element-invisible {
  opacity: 0;
  position: absolute;
  top: -15px;
  left: -15px;
  bottom: -15px;
}
.hint-tip{
  margin-left: 10px;
}

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('z-plate',{attrs:{"title":"管理作品","has-bottom-border":""},scopedSlots:_vm._u([{key:"titleRight",fn:function(){return [(_vm.flag == 'add')?_c('el-button',{attrs:{"type":"primary","icon":"el-icon-circle-plus-outline"},on:{"click":_vm.addVideo}},[_vm._v("添加作品 ")]):_vm._e()]},proxy:true}])},[(_vm.tActivity.fujianFlag == 1)?_c('img',{staticClass:"img",staticStyle:{"position":"absolute","top":"40px","left":"25%","height":"60px"},attrs:{"src":require("../../assets/img/tishi.png"),"alt":""}}):_vm._e(),(_vm.tActivity.fujianFlag == 0)?_c('img',{staticClass:"img",staticStyle:{"position":"absolute","top":"40px","left":"35%","height":"60px"},attrs:{"src":require("../../assets/img/tishi2.png"),"alt":""}}):_vm._e(),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],staticClass:"z-table",staticStyle:{"width":"100%","min-height":"450px"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"60"}}),_c('el-table-column',{attrs:{"label":"作品名称","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('strong',{staticClass:"color-primary cursor",on:{"click":function($event){return _vm.checkWorks(row.videoId, row.type, row.stage, row.videoUrl, row.videoName, row.mpgName)}}},[_vm._v(_vm._s(row.videoName))])]}}])}),_c('el-table-column',{attrs:{"prop":"groupChannel","label":"分类"}}),(_vm.tActivity.fujianFlag == 1)?_c('el-table-column',{attrs:{"prop":"videoTextName","label":"附件"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{staticClass:"color-primary cursor",on:{"click":function($event){return _vm.showFile(row)}}},[_vm._v(_vm._s(row.videoTextName))])]}}],null,false,3079239582)}):_vm._e(),_c('el-table-column',{attrs:{"label":"转码状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.type == 1)?_c('span'):_vm._e(),(row.stage == 1 && row.type == 0)?_c('span',[_vm._v("上传成功")]):_vm._e(),(row.stage == 2 && row.type == 0)?_c('span',[_vm._v("正在转码")]):_vm._e(),(row.stage == 3 && row.type == 0)?_c('span',[_vm._v("转码成功")]):_vm._e(),(row.stage == 4 && row.type == 0)?_c('span',{staticStyle:{"color":"#e60808"}},[_vm._v("转码失败")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.flag == 'add' && _vm.tActivity.show)?_c('el-button',{attrs:{"icon":"el-icon-edit"},on:{"click":function($event){return _vm.editVideo(row)}}}):_vm._e(),(_vm.flag == 'add' && _vm.tActivity.show)?_c('el-button',{attrs:{"icon":"el-icon-delete"},on:{"click":function($event){return _vm.delVideo(row)}}}):_vm._e()]}}])})],1),(_vm.addWorksDialog)?_c('v-add-works',{attrs:{"show":_vm.addWorksDialog,"data":_vm.data},on:{"update:show":function($event){_vm.addWorksDialog=$event},"init":_vm.init}}):_vm._e(),(_vm.dialogVisible)?_c('v-check-works',{attrs:{"show":_vm.dialogVisible,"data":_vm.dialogData},on:{"update:show":function($event){_vm.dialogVisible=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
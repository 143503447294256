<template>
	<z-plate title="管理作品" has-bottom-border>

		<img v-if="tActivity.fujianFlag == 1" src="../../assets/img/tishi.png" alt class="img"
			style="position: absolute; top: 40px; left: 25%; height: 60px;" />
		<img v-if="tActivity.fujianFlag == 0" src="../../assets/img/tishi2.png" alt class="img"
			style="position: absolute; top: 40px; left: 35%; height: 60px;" />

		<template #titleRight>
			<el-button type="primary" icon="el-icon-circle-plus-outline" @click="addVideo" v-if="flag == 'add'">添加作品
			</el-button>
		</template>

		<!-- 表格 -->
		<el-table class="z-table" :data="tableData" v-loading="tableLoading" style="width: 100%;min-height: 450px;">
			<el-table-column type="index" label="序号" width="60"></el-table-column>

			<el-table-column label="作品名称" show-overflow-tooltip>
				<template slot-scope="{row}">
					<strong class="color-primary cursor"
						@click="checkWorks(row.videoId, row.type, row.stage, row.videoUrl, row.videoName, row.mpgName)">{{ row.videoName }}</strong>
				</template>
			</el-table-column>

			<el-table-column prop="groupChannel" label="分类"></el-table-column>

			<el-table-column prop="videoTextName" label="附件" v-if="tActivity.fujianFlag == 1">
				<template #default="{ row }">
					<a class="color-primary cursor" @click="showFile(row)" style="">{{ row.videoTextName }}</a>
				</template>
			</el-table-column>

			<el-table-column label="转码状态">
				<template slot-scope="{ row }">
					<span v-if="row.type == 1"></span>
					<span v-if="row.stage == 1 && row.type == 0">上传成功</span>
					<span v-if="row.stage == 2 && row.type == 0">正在转码</span>
					<span v-if="row.stage == 3 && row.type == 0">转码成功</span>
					<span v-if="row.stage == 4 && row.type == 0" style="color: #e60808;">转码失败</span>
				</template>
			</el-table-column>

			<el-table-column prop="address" label="操作">
				<template slot-scope="{ row }">
					<el-button icon="el-icon-edit" v-if="flag == 'add' && tActivity.show" @click="editVideo(row)">
					</el-button>
					<el-button icon="el-icon-delete" v-if="flag == 'add' && tActivity.show" @click="delVideo(row)">
					</el-button>
				</template>
			</el-table-column>
		</el-table>

		<!-- 分页 -->
		<!--<z-pagination v-if="pagination.total > 0" :page.sync="pagination.page" :limit.sync="pagination.limit" :total="pagination.total" @pagination="init"></z-pagination>-->

		<v-add-works v-if="addWorksDialog" :show.sync="addWorksDialog" :data="data" @init="init"></v-add-works>

		<v-check-works v-if="dialogVisible" :show.sync="dialogVisible" :data="dialogData"></v-check-works>
	</z-plate>
</template>

<script>
import VAddWorks from "./AddWorks";
import VCheckWorks from '../administrator/CheckWorks';
export default {
	components: {
		VAddWorks,
		VCheckWorks
	},

	data() {
		return {
			addDialog: false,
			tableData: [],
			pagination: {
				page: 0, //当前页
				limit: 10, //分页条数
				total: 0 //总页数
			},
			tableLoading: false,
			addWorksDialog: false,
			activityId: sessionStorage.getItem("activityid"),
			flag: sessionStorage.getItem("flag"),
			data: {
				id: "",
				videoId: "",
				fujianFlag: 1
			},
			dialogData: {},
			dialogVisible: false,
			tActivity: {}
		};
	},

	mounted() {
		this.init();
	},

	methods: {
		init() {
			this.tableLoading = true;
			this.$post("/queryUserVideo", {
				activityId: this.activityId
			}).then(m => {
				if (m.resultCode == 0) {
					this.tableData = m.content.list;
					this.tActivity = m.content.tActivity;
					this.tableLoading = false;
				}
			})
		},
		addVideo() {
			this.data.id = this.activityId;
			this.data.videoId = "";
			this.data.fujianFlag = this.tActivity.fujianFlag;
      		this.data.fujianTitle = this.tActivity.fujianTitle;
			this.addWorksDialog = true;
		},
		delVideo(row) {
			//console.log('del',row);

			this.$confirm('确定删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$post("/deleteUserVideo", {
					videoId: row.videoId
				}).then(m => {
					if (m.resultCode == 0) {
						this.$message({
							type: 'success',
							message: '删除成功！'
						});
						this.init();
					}
				})
			}).catch(() => { });

		},
		editVideo(row) {
			this.data.videoId = row.videoId;
			this.data.id = this.activityId;
			this.data.fujianFlag = this.tActivity.fujianFlag;
			this.addWorksDialog = true;
		},
		checkWorks(videoid, type, status, videoUrl, videoName, mpgName) {
			//				console.log(videoid, type, status, videoUrl);
			this.dialogData = {}
			this.$nextTick(() => {
				if (type == 0) { //视频类
					if (status != 3) {
						if (status == 4) {
							this.$message({
								type: 'info',
								message: '视频失败！！！'
							});
						} else if (status == 2) {
							this.$message({
								type: 'info',
								message: '视频正在转码中...'
							});
						}
						return;
					} else {
						this.dialogData = {
							type: 0,
							url: videoUrl,
							name: videoName
						}
						this.dialogVisible = true;
					}
				} else if (type == 1) { //图片类
					this.dialogVisible = true;
					this.loadPicListByVideoId(videoid).then(rep => {
						//							console.log(videoName, rep.content.picList.length );
						let videoname = videoName;
						if (rep.content.picList.length > 1) {
							videoname += "（组图）";
						}

						this.dialogData = {
							type: 1,
							url: rep.content.picList,
							name: videoname
						}
					});
				}else{
          this.$message({
            type: 'info',
            message: '文件正在下载……'
          });
          var fileUrl = videoUrl+"?n="+mpgName;
          // console.log('videoUrl=', fileUrl);
          window.open(fileUrl, '_blank');
        }
			})
		},
		loadPicListByVideoId(videoid) {
			return this.$post('/loadPicListByVideoId', {
				"videoid": videoid
			});
		},
		showFile(obj) {
			var houzhui = obj.videoText.split(".")[1].toLowerCase();
			// console.log('在线查看文件', obj, obj.videoText, houzhui);
			if (houzhui == 'doc' || houzhui == 'docx' || houzhui == 'xls' || houzhui == 'xlsx' || houzhui == 'pdf') {
				let routeUrl = this.$router.resolve({
					path: "/ShowFile",
					query: { videoId: obj.videoId }
				});
				window.open(routeUrl.href, '_blank');
			} else {
				this.$post("/getFileUrl", {
					videoId: obj.videoId
				}).then(m => {
					if (m.resultCode == 0) {
            var obj = m.content.tVideo;
						this.$message({
              type: 'info',
              message: '文件正在下载……'
            });
            var fileUrl = obj.videoUrl+"?n="+obj.mpgName;
            console.log('videoUrl=', fileUrl);
            window.open(fileUrl, '_blank');
					}
				})

			}

		},
	}
};
</script>

<style lang="scss" scoped>
</style>
